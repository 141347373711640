import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { UnitProps } from 'components/app/Unit'

import { Block, Carousel, Text, Title } from 'components/shared'
import { Ribbon } from 'components/helpers'
import { Unit } from 'components/app'
import { WorkUsForm } from 'components/forms'

interface ViewProps {
  units?: UnitProps[]
}

const WorkUsView: React.FC<ViewProps> = ({ units = [] }) => (
  <>
    <Block
      tag="section"
      paddingTop={{ xxxs: 'size64', sm: 'size88' }}
      paddingBottom={{ xxxs: 'size24', sm: 'size48' }}
    >
      <Container>
        <Row justify="space-between">
          <Col xs={12} sm={6} md={5}>
            <Block tag="header" marginBottom="size48">
              <Title
                size="h3"
                icon={{
                  name: 'email',
                  color: 'brown800',
                  width: 'size20',
                  height: 'size20',
                  marginLeft: 'size48',
                  marginRight: 'size8'
                }}
                appearance="section"
                marginRight="size12"
                uppercase
              >
                Trabalhe conosco
              </Title>
            </Block>
            <Block tag="article" marginBottom="size48">
              <WorkUsForm />
            </Block>
          </Col>
        </Row>
      </Container>
    </Block>

    <Block height="size64" bgColor="white" position="relative" zIndex={2}>
      <Ribbon color="brown800" slogan="primary" angle={0.4} delay="-12s" />
    </Block>

    <Block tag="section" paddingTop="size64" paddingBottom="size88">
      <Container>
        <Block tag="header" textAlign="center" marginBottom="size48">
          <Text fontSize={14} uppercase>
            Unidades
          </Text>
          <Title size="h3" uppercase>
            Faça-nos uma visita
          </Title>
        </Block>
        <Block tag="article">
          <Carousel
            swiper={{
              initialSlide: 0,
              observer: true,
              watchOverflow: true,
              simulateTouch: false,
              autoplay: {
                delay: 4000
              },
              spaceBetween: 32,
              slidesPerView: 2,
              breakpoints: {
                0: {
                  spaceBetween: 0,
                  slidesPerView: 1
                },
                962: {
                  spaceBetween: 32,
                  slidesPerView: 2
                }
              }
            }}
          >
            {units.map((unit) => (
              <Unit key={unit.id} {...unit} />
            ))}
          </Carousel>
        </Block>
      </Container>
    </Block>
  </>
)

export default WorkUsView
